import React, { useEffect, useRef, useState } from "react";
import Nav_Dashboard from "../dashboard_nav";
import Navbarr from "../navbar";
import { Link, Navigate } from "react-router-dom";
// import VendorNavbar from "../../Vendor/vendor_navbar";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

const MyBusiness = () => {
  const baseUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEV
    : process.env.REACT_APP_BASE_URL_PROD;


  const [data, setData] = useState([]);
  console.log("top", data);

  const myBusinessData = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      Navigate("/login");
      return;
    }

    const userInfo = jwtDecode(token);
    const email = userInfo.email;
    fetch(`${baseUrl}/list/getListByVendor?email=${email}`)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Unable to fetch data");
        }
      })
      .then((data) => {
        console.log("bottom", data);
        setData(data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    myBusinessData();
  }, []);

  // const data = [
  //     {
  //         img: "../dashboard/dashboard-1.png",
  //         title: "Jem Plumbing Service",
  //         para: "Lorem ipsum dolor sit amet consectetur adipisicing sed do...... ",
  //         status: 'Rejected',
  //     },
  //     {
  //         img: "../dashboard/dashboard-2.png",
  //         title: "Garder Service",
  //         para: "Lorem ipsum dolor sit amet consectetur adipisicing sed do...... ",
  //         status: 'Rejected',
  //     },
  //     {
  //         img: "../dashboard/dashboard-1.png",
  //         title: "Jem Plumbing Service",
  //         para: "Lorem ipsum dolor sit amet consectetur adipisicing sed do...... ",
  //         status: 'Approved',
  //     },
  //     {
  //         img: "../dashboard/dashboard-2.png",
  //         title: "Garder Service",
  //         para: "Lorem ipsum dolor sit amet consectetur adipisicing sed do...... ",
  //         status: 'Pending',
  //     },
  //     {
  //         img: "../dashboard/dashboard-2.png",
  //         title: "Garder Service",
  //         para: "Lorem ipsum dolor sit amet consectetur adipisicing sed do...... ",
  //         status: 'Approved',
  //     },
  //     {
  //         img: "../dashboard/dashboard-2.png",
  //         title: "Garder Service",
  //         para: "Lorem ipsum dolor sit amet consectetur adipisicing sed do...... ",
  //         status: 'Pending',
  //     },
  // ]
  const [accordionOpen, setAccordionOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setAccordionOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="bg-[#008081]">
        {/* <VendorNavbar /> */}
      </div>
      <div className="flex xl:px-[calc((100%-1250px)/2)] px-3 py-5">
        <div className="flex">
          <p className="text-[#444444] text-[12px] md:text-[16px] mr-1 ">
            Home /
          </p>
          <p className="text-[#444444] text-[12px] md:text-[16px] ">
            Vendor Profile /
          </p>
          <p className="text-[#787878] text-[12px] ml-[2px] md:text-[16px] ">
            My Business
          </p>
        </div>
      </div>

      <div className="flex h-[80px] justify-between xl:px-[calc((100%-1250px)/2)] px-3 py-5">
        <p className="md:text-[32px] text-[34px] font-[500] text-[#444444] vol">
          My Business
        </p>
        <div className="relative" ref={dropdownRef}>
          <div className="md:w-[285px] bg-[#ffffff] h-full z-30 absolute right-0 drop-shadow-xl">
            <div className="bg-white">
              <button
                onClick={() => setAccordionOpen(!accordionOpen)}
                className="flex justify-between w-full px-5"
              >
                <div className=" items-center h-[48px] w-full flex justify-between mt-2 rounded-[8px]">
                  <div className="flex items-center">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.211604 1.61C2.5316 4.59 5.9616 9 5.9616 9V14C5.9616 15.1 6.8616 16 7.9616 16C9.0616 16 9.9616 15.1 9.9616 14V9C9.9616 9 13.3916 4.59 15.7116 1.61C16.2216 0.95 15.7516 0 14.9116 0H1.0016C0.171604 0 -0.298396 0.95 0.211604 1.61Z"
                        fill="#008081"
                      />
                    </svg>
                    <p className="pl-2 text-[#212121] hidden md:block">
                      Filter
                    </p>
                  </div>
                </div>
                <img
                  src="../images/up.png"
                  alt=""
                  className={`mr-2 mt-5 transform origin-center rotate-0 transition duration-200 ease-out ${
                    accordionOpen && "!rotate-180"
                  }`}
                />
              </button>
              <div
                className={`grid px-3 overflow-hidden transition-all duration-300 ease-in-out text-[#787878]-600 text-sm ${
                  accordionOpen
                    ? "grid-rows-[1fr] opacity-100"
                    : "grid-rows-[0fr] opacity-0"
                }`}
              >
                <div className="overflow-hidden">
                  <div className="mt-5">
                    <div className="pl-5 h-[40px] hover:bg-[#008081] text-[#444444] cursor-pointer flex items-center hover:text-white">
                      <p className="">All</p>
                    </div>
                    <div className="pl-5 h-[40px] hover:bg-[#008081] text-[#444444] cursor-pointer flex items-center hover:text-white">
                      <p className="">Approved</p>
                    </div>
                    <div className="pl-5 h-[40px] hover:bg-[#008081] text-[#444444] cursor-pointer flex items-center hover:text-white">
                      <p className="">Pending</p>
                    </div>
                    <div className="pl-5 h-[40px] hover:bg-[#008081] text-[#444444] cursor-pointer flex items-center hover:text-white">
                      <p className="">Rejected</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="xl:px-[calc((100%-1250px)/2)] px-3 mb-20">
        <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-x-4">
          {Array.isArray(data) &&
            data.map((item, index) => (
              <div className="mt-4" key={index}>
                <img
                  src={item.img}
                  alt=""
                  className="w-full h-[210px] rounded-t-[12px]"
                />
                <div className="pl-2">
                  <div className="flex justify-between py-2 ">
                    <p className="text-[14px] font-[500] text-[#444444]">
                      {item.ListName}
                    </p>
                    <p
                      className={`rounded-[20px] text-[#ffffff] px-5 h-[20px] text-[12px] ${
                        item.status === "Approved"
                          ? "bg-[#B6F8C7] text-[#1A8537]"
                          : item.status === "Pending"
                          ? "bg-[#FAC9A5] text-[#FF6F07]"
                          : item.status === "Rejected"
                          ? "bg-[#FFE5EE] text-[#AA0000]"
                          : ""
                      }`}
                    >
                      {" "}
                      {item.status}
                    </p>
                  </div>
                  {/* <div className="w-[260px] pb-2 ">
                            <p className="text-[12px] text-[#757575]">{item.para}</p>
                        </div> */}
                  <Link to={`/mybusiness_listing?serviceId=${item.serviceId}`}>
                    <button className="w-[120px] h-[40px] bg-[#008081] text-white rounded-[8px]">
                      View Business
                    </button>
                  </Link>
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* <div className="h-[50vh] flex justify-center items-center">
                <p className="text-[#A49595] pb-20 text-[24px]">There is no business listing here</p>
            </div> */}
    </>
  );
};

export default MyBusiness;
