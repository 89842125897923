import React, { useEffect } from 'react'
import Navbarr from '../navbar'

export default function PrivacyPolicy() {
    useEffect(() => {
        window.scroll(0, 0);
    })
    return (
        <>
            <Navbarr />
            <div className="flex xl:px-[calc((100%-1250px)/2)] px-3 py-5">
                <p className="text-[#444444] text-[12px] md:text-[16px] mr-1 ">
                    Home /
                </p>
                <p className="text-[#787878] text-[12px] md:text-[16px] ">
                    Privacy Policy
                </p>
            </div>
            <div className="xl:px-[calc((100%-1250px)/2)] px-3 py-5 text-[#787878] text-[12px] md:text-[16px] ">
                <p className="pb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin rhoncus vitae mi non porttitor. Phasellus sit amet egestas erat. Fusce sed lorem nibh. Sed sed leo metus. Pellentesque gravida mattis vulputate. Aliquam ut tristique augue. Curabitur vel tellus lacus. Sed hendrerit in nulla eget aliquet. Donec pulvinar sapien ut dolor hendrerit euismod. Maecenas non hendrerit nibh. Suspendisse potenti. Sed mollis nisi et dui consectetur, sed euismod eros ultrices. Maecenas et odio vel libero tempus porttitor consequat vel nisi.</p>

               <p className="pb-2"> Praesent placerat nulla sed nisl laoreet sodales. Nulla molestie mauris varius, semper odio vitae, laoreet felis. Nullam auctor lacinia nulla, quis pharetra odio blandit id. Donec sit amet ornare lorem. In pulvinar rhoncus est et fringilla. Cras non libero pellentesque, venenatis lacus a, venenatis nisi. Donec quis venenatis sapien. Nulla consequat nunc et lacus sollicitudin viverra. Quisque imperdiet ante in nisi aliquet bibendum. Maecenas posuere pharetra ornare. Curabitur sit amet felis ac magna tempus fringilla. Nulla eros felis, volutpat et mattis et, sollicitudin a eros. Proin at lobortis nulla. Nulla neque diam, porttitor sit amet nulla id, tempor euismod eros. Nam sodales est tincidunt eros interdum, vitae elementum ligula tristique. Etiam nec mauris tempus, aliquet purus vestibulum, bibendum magna.</p>

              <p className="pb-2">  Cras ac mi volutpat, facilisis massa non, eleifend magna. Sed eu velit diam. Vivamus commodo varius sapien, et vehicula velit tincidunt sit amet. Cras sit amet nibh ac purus dapibus pharetra. Nam volutpat, massa ac sollicitudin iaculis, lorem libero hendrerit nunc, et iaculis diam felis vitae neque. Vestibulum vitae cursus diam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi vel cursus est, at sagittis neque. Etiam id felis id metus efficitur aliquet id nec nulla. Etiam tincidunt egestas lacinia. Pellentesque urna tortor, tincidunt non odio sit amet, lobortis dapibus leo. Etiam in porta augue, ut pellentesque erat.</p>

              <p className="pb-2">  Mauris consequat risus blandit, sagittis felis quis, ullamcorper augue. Sed sed metus eget ex scelerisque mattis. Aliquam elementum sem at augue commodo accumsan. Integer commodo fermentum malesuada. Vestibulum at porttitor dolor, vitae fringilla ex. Duis tempor mi at ipsum rutrum, sit amet vulputate eros varius. Donec a congue ante. Sed sed elit nisi. Etiam dignissim, mauris ultrices dignissim dignissim, turpis ligula facilisis arcu, in rhoncus tortor justo id diam. In ut lorem dui. Sed fringilla elit quis suscipit vulputate. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi venenatis diam ut enim pharetra iaculis. Fusce enim erat, malesuada ac eleifend eu, lobortis non ligula. Curabitur finibus tempus venenatis.</p>

              <p className="pb-2">  Mauris vulputate ullamcorper nulla. Nullam quis nisl sed est mattis venenatis non at augue. Praesent nec lorem ut est maximus interdum. Cras nibh lorem, tempor nec eros non, vehicula blandit nisi. Vivamus semper sapien ac dui pulvinar molestie. Duis tempor tellus est, id scelerisque tortor vehicula quis. Nam ullamcorper est sit amet accumsan viverra. Duis ultrices quam ut risus semper fermentum. Duis pretium est eget massa venenatis, ac tristique ipsum vulputate. Donec cursus lectus eros, in porta quam hendrerit et. Pellentesque et nisl vel mauris sollicitudin interdum. Praesent congue imperdiet massa, nec gravida purus tempor nec. Morbi sed consequat neque. Morbi imperdiet ex vel porta tincidunt. Praesent tincidunt, justo quis sollicitudin luctus, mauris erat efficitur enim, id iaculis eros lacus ac ex.</p>
            </div>
        </>
    )
}

