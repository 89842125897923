import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "../payment";
import { useLocation } from "react-router-dom";
import { Navbar } from "@material-tailwind/react";
import Navbarr from "../navbar";
import { CheckCircleIcon } from "@heroicons/react/solid";

const stripePromise = loadStripe(
  "pk_test_51QATbFL4ZkdpYzHeM81NUhnKOaSDDtkS6lO1ixkbS7yx4AZLzKvo9VhnNiu7Wm4cy9WJ2SlCrhVqlTvimUZLsDKv00mIFECtDg",
  {
    betas: ["custom_checkout_beta_3"],
  }
);

const Paymentcard = () => {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const location = useLocation();

  const { totalAmount, currency, taxAmount, subTotal, orderSummary } =
    location.state || {};

  const searchParams = new URLSearchParams(location.search);
  const subTotalValue =
    typeof subTotal === "string" ? parseFloat(subTotal) : subTotal;

  const formattedSubTotal = Number.isFinite(subTotalValue)
    ? Math.floor(subTotalValue)
    : 0;

  console.log(totalAmount, "f", subTotal, formattedSubTotal);
  let payementRef = searchParams.get("payementRef");

  const [clientSecret, setClientSecret] = React.useState(null);
  const hasFetchedPaymentIntent = React.useRef(false);
  console.log(clientSecret, "hghhghg");

  const fetchPaymentIntent = async () => {
    try {
      const response = await fetch(`${baseUrl}/booking/create-payment-intent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ amount: formattedSubTotal, currency: "eur" }),
      });

      const { clientSecret } = await response.json();
      setClientSecret(clientSecret);
      hasFetchedPaymentIntent.current = true;
    } catch (error) {
      console.error("Error fetching payment intent:", error);
    }
  };

  React.useEffect(() => {
    if (!hasFetchedPaymentIntent.current) {
      fetchPaymentIntent();
    }
  }, []);

  if (clientSecret) {
    return (
      <>
        <div className="absolute w-full bg-white">
          <Navbarr />
        </div>
        <div className="flex items-center justify-center min-h-screen bg-gray-100 px-4 sm:px-6 lg:px-8 mb-5 ">
          <div className="w-full max-w-[550px] p-6 bg-white rounded-lg shadow-md mt-[100px]">
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <PaymentForm clientSecret={clientSecret} />
            </Elements>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center min-h-screen bg-green-50 text-green-700 p-6 ">
      <CheckCircleIcon className="w-16 h-16 text-green-500 mt-20 lg:mt-0" />
      <h1 className="mt-4 text-2xl font-semibold">Payment Successful!</h1>
      <p className="mt-2 text-center">
        Thank you for your payment. Your transaction has been completed, and a
        receipt has been sent to your email.
      </p>
      <p className="mt-4 p-2 rounded-lg ">
        You will be redirected to the dashboard in{" "}
        <span className="bg-green-600 rounded-lg text-[#ffffff] p-2">
          {/* {timeLeft} */}
          seconds
        </span>
      </p>
      <button
        className="mt-8 px-6 py-3 bg-green-600 text-white rounded-md shadow hover:bg-green-700"
        // onClick={() => navigate("/")}  
      >
        Go to Dashboard
      </button>
    </div>

      </>
    );
  } else {
    return <p>Loading...</p>;
  }
};

export default Paymentcard;
