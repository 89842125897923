import React, { useState } from "react";
import My_Profile from "../My profile";
import Booking_History from "../booking history";
import Navbarr from "../navbar";
import { Link } from "react-router-dom";

const User_Profile = () => {
  const [div1, setDiv1] = useState(true);
  const Div1 = () => {
    setDiv2(false);
    setDiv1(true);

  };
  const [div2, setDiv2] = useState(false);
  const Div2 = () => {
    setDiv2(true);
    setDiv1(false);

  };
  return (
    <>
      <Navbarr />
      <div className="mt-5 flex xl:px-[calc((100%-1250px)/2)] px-3 py-5">
        <Link className="flex gap-2 cursor-pointer" to="/">
          <span class="material-symbols-outlined ">arrow_back</span>
          <p className="text-[#444444] text-[12px] md:text-[16px] mr-1">Back</p>
        </Link>
      </div>
      <div className=" xl:px-[calc((100%-1250px)/2)] px-3 w-full overflow">
        <div className="md:flex">
          <div className="flex gap-10 md:block md:w-[100px] lg:w-[210px] animate__animated animate__bounceInLeft">
            <div
              className="flex h-[30px] items-center mt-6 cursor-pointer"
              onClick={Div1}
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.9827 17.9038C9.47052 17.9038 5.61719 18.586 5.61719 21.3183C5.61719 24.0505 9.44608 24.7571 13.9827 24.7571C18.495 24.7571 22.3472 24.0738 22.3472 21.3427C22.3472 18.6116 18.5194 17.9038 13.9827 17.9038Z"
                  stroke="#757575"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.9833 14.0068C16.9445 14.0068 19.3445 11.6057 19.3445 8.64456C19.3445 5.68345 16.9445 3.28345 13.9833 3.28345C11.0222 3.28345 8.62113 5.68345 8.62113 8.64456C8.61113 11.5957 10.9956 13.9968 13.9456 14.0068H13.9833Z"
                  stroke="#757575"
                  stroke-width="1.42857"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="text-[#008081] text-[12px] md:text-[16px] ml-2 md:hidden lg:flex">
                Profile
              </p>
              <div className="ml-4 lg:ml-20">
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_557_6660)">
                    <path
                      d="M4.88756 6L0.256281 10.5368C-0.0854273 10.8715 -0.0854273 11.4142 0.256281 11.7489C0.59799 12.0837 1.15201 12.0837 1.49372 11.7489L6.74372 6.60609C7.08543 6.27136 7.08543 5.72864 6.74372 5.39391L1.49372 0.251051C1.15201 -0.083684 0.59799 -0.083684 0.256281 0.251051C-0.0854273 0.585786 -0.0854273 1.1285 0.256281 1.46323L4.88756 6Z"
                      fill="#757575"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_557_6660">
                      <rect width="7" height="12" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
            <div
              className="flex h-[30px] items-center mt-6 cursor-pointer"
              onClick={Div2}
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.60742 10.9715H24.402"
                  stroke="#757575"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.1815 15.5279H19.1923"
                  stroke="#757575"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.0057 15.5279H14.0165"
                  stroke="#757575"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.8182 15.5279H8.82901"
                  stroke="#757575"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.1815 20.0621H19.1923"
                  stroke="#757575"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.0057 20.0621H14.0165"
                  stroke="#757575"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.8182 20.0621H8.82901"
                  stroke="#757575"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.7181 2.33325V6.1725"
                  stroke="#757575"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.29235 2.33325V6.1725"
                  stroke="#757575"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18.9446 4.17554H9.06612C5.63999 4.17554 3.5 6.08413 3.5 9.5924V20.1503C3.5 23.7138 5.63999 25.6665 9.06612 25.6665H18.9338C22.3708 25.6665 24.5 23.7469 24.5 20.2386V9.5924C24.5108 6.08413 22.3816 4.17554 18.9446 4.17554Z"
                  stroke="#757575"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <p className="text-[#008081] text-[12px] md:text-[16px] ml-2 md:hidden lg:flex">
                Booking History
              </p>
              <div className="ml-4">
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_557_6660)">
                    <path
                      d="M4.88756 6L0.256281 10.5368C-0.0854273 10.8715 -0.0854273 11.4142 0.256281 11.7489C0.59799 12.0837 1.15201 12.0837 1.49372 11.7489L6.74372 6.60609C7.08543 6.27136 7.08543 5.72864 6.74372 5.39391L1.49372 0.251051C1.15201 -0.083684 0.59799 -0.083684 0.256281 0.251051C-0.0854273 0.585786 -0.0854273 1.1285 0.256281 1.46323L4.88756 6Z"
                      fill="#757575"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_557_6660">
                      <rect width="7" height="12" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>

          {div1 && (
            <div className="w-full">
              <My_Profile />
            </div>
          )}
          {div2 && (
            <div className="w-full">
              <Booking_History />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default User_Profile;
