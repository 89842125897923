import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Navbarr from "../navbar";
import ForgotPassword from "../forgotpassword";
import ResetPassword from "../resetpassword";
import Register from "../register";

function LoginPopup() {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get("navUrl");
  const [showPassword, setShowPassword] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    const lowerCaseValue = name === "email" ? value.toLowerCase() : value;

    setFormData({ ...formData, [name]: lowerCaseValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError("");
      const { email, password } = formData;

      const response = await axios.post(`${baseUrl}/users/login`, {
        email,
        password,
      });

      if (response.data.isSuccess) {
        localStorage.setItem("token", response.data.result.token);

        console.log("loginscxcxccc", response.data.result.token);

        window.location = "/";
      } else {
        setError(response.data.message);
      }
    } catch (err) {
      if (
        err.response &&
        err.response.status >= 400 &&
        err.response.status <= 500
      ) {
        setError(err.response.data.message);
      } else {
        setError("Internal Server Error");
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openPopup = () => {
    setLogin(true);
    document.body.classList.add("popup-open");
  };

  const closePopup = () => {
    setResetpassword(false);
    setLogin(false);
    setForgotpassword(false);
    document.body.classList.remove("popup-open");
  };

  // forgot password popup

  const [forgotpassword, setForgotpassword] = useState(false);

  const Forgotpassword = () => {
    setForgotpassword(true);
    setLogin(false);
    document.body.classList.add("popup-open");
  };

  // Reset password popup

  const [resetpassword, setResetpassword] = useState(false);

  const Resetpassword = () => {
    setResetpassword(true);
    setForgotpassword(false);
    setLogin(false);
    document.body.classList.add("popup-open");
  };

  const [login, setLogin] = useState(false);
  const [register, SetRegister] = useState(false);

  const openRegister = () => {
    SetRegister(true);
    setLogin(false);
    document.body.classList.add("popup-open");
  };

  const GotoSignin = () => {
    setLogin(true);
    SetRegister(false);
    document.body.classList.add("popup-open");
  };

  return (
    <>
      <div className="flex justify-center">
        <form onSubmit={handleSubmit} className="w-[30%] mt-10">
          <p className="md:text-[48px] text-[34px] font-[700] text-[#444444] md:py-5 vol">
            Login in to your <br />
            <span className="text-[#008081] ml-2 vol">account</span>
          </p>

          <input
            type="text"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your Email address"
            className="w-full h-[48px] border border-[#DDDDDD] text-[#ADA4A5] text-[12px] pl-2 rounded-[10px] mb-5"
          />

          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              placeholder="Enter your password"
              className="w-full h-[48px] border border-[#DDDDDD] text-[#ADA4A5] text-[12px] pl-2 rounded-[10px] mb-5"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute inset-y-0 right-0 px-2 -top-2"
            >
              {showPassword ? (
                <span class="material-symbols-outlined !text-[16px] ">
                  visibility
                </span>
              ) : (
                <span class="material-symbols-outlined !text-[16px] ">
                  visibility_off
                </span>
              )}
            </button>
          </div>
          <p
            onClick={Forgotpassword}
            className="text-[#008081] text-[12px] underline-offset-2 link "
          >
            {" "}
            Forget Password ?{" "}
          </p>

          <div className="grid justify-items-center">
            <button className="w-[120px] h-[40px] bg-[#008081] text-white rounded-[4px] mb-5 mt-2">
              Sign In
            </button>
          </div>
        </form>

        {error && (
          <p className="error-message text-red-500 justify-center">{error}</p>
        )}
      </div>
      <p className="text-[14px] text-[#444444] w-full text-center">
        Don’t have an account yet?
        <span className="text-[#008081] cursor-pointer" onClick={openRegister}>
          Register Now
        </span>
      </p>
      {register && (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#868885] bg-opacity-[70%] z-30">
          <div className="text-[#444444] lg:w-[720px] md:w-[450px] bg-white rounded-t-[40px] md:rounded-[0px] pb-10 opacity-100 md:float-right h-full lg:p-6 p-4 m-4 md:m-0">
            <div className="flex justify-center md:hidden" onClick={closePopup}>
              <svg
                width="51"
                height="5"
                viewBox="0 0 51 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.1"
                  x="0.5"
                  width="50"
                  height="5"
                  rx="2.5"
                  fill="#1D1617"
                />
              </svg>
            </div>
            <div
              className="hidden md:block float-right cursor-pointer"
              onClick={closePopup}
            >
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.4 9.99844L0 8.59844L3.6 4.99844L0 1.42344L1.4 0.0234375L5 3.62344L8.575 0.0234375L9.975 1.42344L6.375 4.99844L9.975 8.59844L8.575 9.99844L5 6.39844L1.4 9.99844Z"
                  fill="#787878"
                />
              </svg>
            </div>
            <Register GotoSignin={GotoSignin} />
            <div className="flex justify-center">
              <p className="text-[14px] text-[#444444]">
                Already have an account yet?
                <span
                  onClick={GotoSignin}
                  className="text-[#008081] cursor-pointer"
                >
                  Sign In Now
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
      {forgotpassword && (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#868885] bg-opacity-[70%] z-30">
          <div className="text-[#444444] lg:w-[720px] md:w-[450px] bg-white rounded-t-[40px] md:rounded-[0px] pb-10 opacity-100 md:float-right h-full lg:p-6 p-4 m-4 md:m-0">
            <div className="flex justify-center md:hidden" onClick={closePopup}>
              <svg
                width="51"
                height="5"
                viewBox="0 0 51 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.1"
                  x="0.5"
                  width="50"
                  height="5"
                  rx="2.5"
                  fill="#1D1617"
                />
              </svg>
            </div>
            <div
              className="hidden md:block float-right cursor-pointer"
              onClick={closePopup}
            >
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.4 9.99844L0 8.59844L3.6 4.99844L0 1.42344L1.4 0.0234375L5 3.62344L8.575 0.0234375L9.975 1.42344L6.375 4.99844L9.975 8.59844L8.575 9.99844L5 6.39844L1.4 9.99844Z"
                  fill="#787878"
                />
              </svg>
            </div>
            <ForgotPassword />
          </div>
        </div>
      )}

      {resetpassword && (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#868885] bg-opacity-[70%] z-30">
          <div className="text-[#444444] lg:w-[720px] md:w-[450px] bg-white rounded-t-[40px] md:rounded-[0px] pb-10 opacity-100 md:float-right h-full lg:p-6 p-4 m-4 md:m-0">
            <div className="flex justify-center md:hidden" onClick={closePopup}>
              <svg
                width="51"
                height="5"
                viewBox="0 0 51 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.1"
                  x="0.5"
                  width="50"
                  height="5"
                  rx="2.5"
                  fill="#1D1617"
                />
              </svg>
            </div>
            <div
              className="hidden md:block float-right cursor-pointer"
              onClick={closePopup}
            >
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.4 9.99844L0 8.59844L3.6 4.99844L0 1.42344L1.4 0.0234375L5 3.62344L8.575 0.0234375L9.975 1.42344L6.375 4.99844L9.975 8.59844L8.575 9.99844L5 6.39844L1.4 9.99844Z"
                  fill="#787878"
                />
              </svg>
            </div>
            <ResetPassword />
            <div className="flex justify-center">
              <button
                type="submit"
                className="w-[173px] roboto h-[41px] bg-[#008081] text-[#ffffff] rounded-[4px] ml-3"
              >
                Reset Password
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LoginPopup;
