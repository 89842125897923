import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="bg-[#F7F8FC] p-2 md:p-8 mb-10 md:mb-0">
        <div className="lg:px-[calc((100%-1250px)/2)] grid grid-cols-1 lg:grid-cols-4 lg:gap-20">
          <div className="w-[300px] md:w-[500px] lg:w-[370px] text-[#444444]">
            <h2 className="text-[18px] font-bold mb-4">Nexlum Services</h2>
            <p className="text-[16px] font-[400] lg:w-[280px] xl:w-[340px] text-justify">
              Lorem ipsum dolor sit amet consectetur adipisicing sed do eiusmod
              tempor incididunt laboredolore magna aliqua enim.
            </p>

            {/* <div className="social-icons flex space-x-6 mt-2 ">
              <div className="cursor-pointer ss h-10 w-10 p-3 rounded-full grid place-content-center drop-shadow-xl bg-white hover:bg-[#008081]">
                <svg width="6" height="13" viewBox="0 0 6 13" className="h-10 w-10 p-3" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.54485 2.79909C5.2326 2.70031 4.8735 2.63445 4.54563 2.63445C4.1397 2.63445 3.26538 2.91434 3.26538 3.45765V4.75831H5.34189V6.94803H3.26538V12.9903H1.17327V6.94803H0.142822V4.75831H1.17327V3.65522C1.17327 1.99235 1.89146 0.609375 3.62448 0.609375C4.21776 0.609375 5.27943 0.642303 5.85711 0.856335L5.54485 2.79909Z" />
                </svg>
              </div>
              <div className="cursor-pointer ss h-10 w-10 p-3 rounded-full grid place-content-center drop-shadow-xl bg-white hover:bg-[#008081]">
                <svg width="18" height="18" viewBox="0 0 18 18" className="h-10 w-10 p-3" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.5667 0.933594H2.5C1.12167 0.933594 0 2.05526 0 3.43359V15.5003C0 16.8789 1.12167 18.0003 2.5 18.0003H14.5667C15.9453 18.0003 17.0667 16.8789 17.0667 15.5003V3.43359C17.0667 2.05526 15.9453 0.933594 14.5667 0.933594ZM16.0667 15.5003C16.0667 16.3273 15.3937 17.0003 14.5667 17.0003H2.5C1.673 17.0003 1 16.3273 1 15.5003V3.43359C1 2.60659 1.673 1.93359 2.5 1.93359H14.5667C15.3937 1.93359 16.0667 2.60659 16.0667 3.43359V15.5003Z" />
                  <path d="M14.0334 2.9668C13.4821 2.9668 13.0334 3.41546 13.0334 3.9668C13.0334 4.51813 13.4821 4.9668 14.0334 4.9668C14.5848 4.9668 15.0334 4.51813 15.0334 3.9668C15.0334 3.41546 14.5848 2.9668 14.0334 2.9668Z" />
                  <path d="M8.53345 4.9668C6.05211 4.9668 4.03345 6.98546 4.03345 9.4668C4.03345 11.9481 6.05211 13.9668 8.53345 13.9668C11.0148 13.9668 13.0334 11.9481 13.0334 9.4668C13.0334 6.98546 11.0148 4.9668 8.53345 4.9668ZM8.53345 12.9668C6.60345 12.9668 5.03345 11.3968 5.03345 9.4668C5.03345 7.5368 6.60345 5.9668 8.53345 5.9668C10.4634 5.9668 12.0334 7.5368 12.0334 9.4668C12.0334 11.3968 10.4634 12.9668 8.53345 12.9668Z" />
                </svg>
              </div>
              <div className="cursor-pointer ss h-10 w-10 p-3 rounded-full grid place-content-center drop-shadow-xl bg-white hover:bg-[#008081]">
                <svg width="17" height="14" viewBox="0 0 17 14" className="h-10 w-10 p-3" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.317 2.15967C15.7365 2.4143 15.118 2.58308 14.4731 2.66503C15.1365 2.26893 15.6429 1.6465 15.8809 0.896252C15.2624 1.26503 14.5795 1.52552 13.8517 1.67089C13.2643 1.04552 12.4273 0.658203 11.5141 0.658203C9.7424 0.658203 8.31606 2.09625 8.31606 3.85918C8.31606 4.11284 8.33752 4.35674 8.3902 4.58893C5.72971 4.45918 3.37557 3.18406 1.7941 1.24162C1.51801 1.72064 1.35606 2.26893 1.35606 2.85918C1.35606 3.96747 1.92679 4.94991 2.77752 5.51869C2.26337 5.50893 1.75898 5.35967 1.33167 5.12454C1.33167 5.1343 1.33167 5.14698 1.33167 5.15967C1.33167 6.71479 2.44093 8.0065 3.89557 8.30406C3.63508 8.37528 3.35118 8.40942 3.05654 8.40942C2.85167 8.40942 2.64484 8.39772 2.45069 8.35479C2.86532 9.62211 4.04191 10.5538 5.44093 10.5841C4.35215 11.4358 2.96971 11.9489 1.47313 11.9489C1.21069 11.9489 0.958983 11.9372 0.707275 11.905C2.12484 12.8192 3.80484 13.3411 5.61654 13.3411C11.5053 13.3411 14.7248 8.46308 14.7248 4.23479C14.7248 4.09333 14.72 3.95674 14.7131 3.82113C15.3483 3.3704 15.8819 2.80747 16.317 2.15967Z"/>
                </svg>
              </div>
            </div> */}
          </div>

          <div className="mt-6 md:mt-5 lg:mt-0 w-[200px] lg:ml-[60px]">
            <h2 className="text-[18px] font-bold text-[#444444] mb-2 md:mb-4">
              Company
            </h2>
            <ul className="list-none leading-[30px] space-y-1 text-[16px] font-[400]  text-[#444444]">
              <Link to={"/"}>
                <li className="">About</li>
              </Link>
              <Link to={"/"}>
                <li className="">Careers</li>
              </Link>
              <Link to={"/categories"}>
                <li className="">Categories</li>
              </Link>
              <Link to={"/"}>
                <li className="">Contact</li>
              </Link>
            </ul>
          </div>

          <div className="mt-1 md:mt-0 w-[200px] ">
            <h2 className="text-[18px] font-bold text-[#444444] mb-2 md:mb-4">
              Policy
            </h2>
            <ul className="list-none leading-[30px] space-y-1 text-[16px] font-[400]  text-[#444444]">
              <Link to={"/Terms and Condition"}>
                <li className="">Terms & Conditions</li>
              </Link>
              <Link to={"/privacypolicy"}>
                <li className="">Privacy Policy</li>
              </Link>
              <Link to={"/cancellationpolicy"}>
                <li className="">Cancellation Policy</li>
              </Link>
            </ul>
          </div>

          <div className="mt-1 md:mt-0 w-[200px] ">
            <h2 className="text-[18px] font-bold text-[#444444] mb-2 md:mb-4">
              More
            </h2>
            <ul className="list-none leading-[30px] space-y-1 text-[16px] font-[400]  text-[#444444]">
              <a
                href="http://localhost:3001/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <li className="">Become Vendor</li>
              </a>
              {/* <li className="">Send Enquiry</li> */}
            </ul>
          </div>
        </div>
        <hr className="border-[1px] mx-auto  lg:px-[calc((100%-1250px)/2)] w-full xl:w-[85%] mt-5  border-[#999999]" />
        <p className="lg:px-[calc((100%-1250px)/2)] text-center py-3 text-[#444444] text-[10px] lg:text-[14px] ">
          Copyright 2024. All rights reserved - Nexlum Services- Designed and
          Developed by Meerana Technologies LLP
        </p>
      </footer>
    </>
  );
};

export default Footer;
