import React from "react";
import { useNavigate } from "react-router-dom";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

function Failed() {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-red-50 text-red-700 p-6">
      <ExclamationCircleIcon className="w-16 h-16 text-red-500" />
      <h1 className="mt-4 text-2xl font-semibold">Payment Failed</h1>
      <p className="mt-2 text-center">
        Unfortunately, we couldn't complete your transaction. Please try again
        or contact support for assistance.
      </p>
      <button
        className="mt-8 px-6 py-3 bg-red-600 text-white rounded-md shadow hover:bg-red-700"
        onClick={() => window.location.reload()}
      >
        Try Again
      </button>
      <button
        className="mt-4 px-6 py-3 bg-gray-200 text-gray-700 rounded-md shadow hover:bg-gray-300"
        onClick={handleBack}
      >
        Contact Support
      </button>
    </div>
  );
}

export default Failed;
