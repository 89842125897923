import { create } from "zustand";
const baseUrl =
process.env.NODE_ENV === "development"
  ? process.env.REACT_APP_BASE_URL_DEV
  : process.env.REACT_APP_BASE_URL_PROD;



export const useCurrencyStore = create((set, get) => ({
  
  currency: "USD",
  currencyRate: 1,
  getCurrency: () => {
    const storedCurrency = JSON.parse(localStorage.getItem("currency"));
    if (storedCurrency && get().currency !== storedCurrency.currency) {
      set({ currency: storedCurrency.currency });
      set({ currencyRate: storedCurrency.currencyRate });
    }
  },

  changeCurrency: (currency) => {
    var _body = JSON.stringify({ currency: currency });
    fetch(`${baseUrl}/currency/set-currency`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", 
      },
      body: _body,
    })
      .then((res) => res.json())
      .then((data) => {
        if (get().currency !== data.result?.currency) {
          set({ currency: data.result?.currency });
          set({ currencyRate: data.result?.currencyRate });
          let currency = {
            currency: data.result?.currency,
            currencyRate: data.result?.currencyRate,
          };
          localStorage.setItem("currency", JSON.stringify(currency));
        }
      });
  },
}));



export const useWishlistStore = create((set, get) => ({
  wishlist: [],
  isWishlistAffected: 0,

  initiateWishlist: (user_id) => {
      const wishlist = [];

      fetch(`${baseUrl}/wishlist/get-wishlish-info?userId=${user_id}`, {
          method: 'GET',
      })
          .then((res) => res.json())
          .then((data) => {
              if (data && data.result.length > 0) {
                  const wishlist_arr = data.result;
                  wishlist_arr.map((element) => {
                      wishlist.push(element);
                      return null;
                  });
                  set({ wishlist: wishlist });
              } else set({ wishlist: [] });
          })
          .catch((err) => {
              console.log(err);
          });
  },

  addToWishlist: (productInfo) => {
      const wishlist = get().wishlist;

      if (productInfo) {
          wishlist.push({ ...productInfo });
          get().initiateWishlistApi(`${baseUrl}/wishlist/add-to-wishlist`, productInfo);
      }

      set({ wishlist: [...wishlist] });
  },

 
  
}));
