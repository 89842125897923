import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useCurrencyStore } from "../../store/store";
import PaymentForm from "../payment";

const OrderSummary = ({ id, closePopup, PaymentOpen, ConformCancel }) => {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const [currency, currencyRate] = useCurrencyStore((state) => [
    state.currency,
    state.currencyRate,
  ]);

  const [orderSummary, setOrderSummary] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false); // Track if payment modal is open
  const [isOrderSummaryVisible, setIsOrderSummaryVisible] = useState(true); // Track visibility of order summary

  const fetchServiceDetails = async () => {
    try {
      const token = localStorage.getItem("token");

      const response = await fetch(
        `${baseUrl}/booking/checkout?serviceId=${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setOrderSummary(data.result);

      // Calculate total amount
      if (data.result.services) {
        const calculatedTotalAmount = data.result.services.reduce(
          (total, service) => {
            const price = parseFloat(service.price);
            return total + price * service.count;
          },
          0
        );

        setTotalAmount(calculatedTotalAmount);

        // Calculate tax (15%)
        const tax = calculatedTotalAmount * 0.15;
        setTaxAmount(tax);

        // Calculate subtotal (total + tax)
        const calculatedSubTotal = calculatedTotalAmount + tax;
        setSubTotal(calculatedSubTotal);
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  useEffect(() => {
    fetchServiceDetails();
  }, [id]);

  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();

    const weekday = d.toLocaleDateString("en-US", { weekday: "long" });

    return `${day}/${month}/${year} (${weekday})`;
  };

  const totalCount = orderSummary.services?.reduce(
    (total, item) => total + item.count,
    0
  );

  // const handlePaymentOpen = () => {
  //   setIsPaymentOpen(true); // Open payment modal
  //   setIsOrderSummaryVisible(false); // Hide order summary
  //   PaymentOpen(); // Call any additional function passed from parent
  // };

  // const handlePaymentClose = () => {
  //   setIsPaymentOpen(false); // Close payment modal
  //   setIsOrderSummaryVisible(true); // Show order summary when payment is closed
  // };

  return (
    <>
      <div className="fixed top-0 left-0 right-0 bottom-0 z-30">
        <div className="text-[#444444] overflow-y-scroll lg:w-[720px] md:w-[450px] bg-white rounded-t-[40px] md:rounded-[0px] pb-10 opacity-100 md:float-right h-full lg:p-6 p-4 m-4 md:m-0">
          <div>
            <div className="flex ">
              <Link
                className="flex items-center gap-2 cursor-pointer"
                onClick={closePopup}
              >
                <span className="material-symbols-outlined">arrow_back</span>
                <p className="text-[#444444] text-[12px] md:text-[16px] mr-1">
                  Back
                </p>
              </Link>
            </div>

            {/* Booking Content */}
            {isOrderSummaryVisible && (
              <div className="pb-10">
                <p className="md:text-[48px] text-[34px] font-[700] text-[#444444] md:py-5">
                  Order
                  <span className="text-[#008081] ml-2">summary</span>
                </p>

                <div className="flex justify-center">
                  <img
                    src={orderSummary.thumbnails}
                    alt=""
                    className="w-[240px] md:w-[350px] h-[170px] md:h-[210px] rounded-[12px]"
                  />
                </div>

                <div className="mt-4 text-center">
                  <p className="md:text-[24px] text-[14px] text-[#212121] font-semibold">
                    {orderSummary.listName}
                  </p>
                </div>

                <div className="mt-5">
                  <p className="md:text-[24px] text-[16px] text-[#008081] py-3 font-semibold">
                    Service Summary
                  </p>
                  {orderSummary.services?.map((item, index) => (
                    <div className="flex gap-5 items-center mt-3 text-[10px] md:text-[16px]" key={index}>
                      <img
                        src={item.imageUrl}
                        alt=""
                        className="h-[40px] w-[40px] md:h-[70px] md:w-[70px] rounded-full border-2 border-[#008081]"
                      />
                      <p className="font-semibold text-[#008081]  p-2">
                        {item.serviceName} x <span>{item.count}</span>
                      </p>
                    </div>
                  ))}
                </div>

                <div className="mt-3 font-semibold">
                  <p className="text-[#212121] text-[12px] md:text-[18px]">
                    Date:{" "}
                    <span className="font-normal">
                      {formatDate(orderSummary.date)}
                    </span>
                  </p>
                  <p className="text-[#212121] text-[12px] md:text-[18px]">
                    Time:{" "}
                    <span className="font-normal">{orderSummary.slots} </span>
                  </p>
                </div>

                <div className="border-2 border-[#008081] rounded-lg p-2 md:p-5 mb-5 mt-5">
                  <p className="md:text-[24px] text-[16px] text-[#008081] font-semibold">
                    Address Details
                  </p>
                  <div className="text-[#212121] text-[12px] mt-2">
                    <p className="lg:w-[300px] xl:w-full w-full text-[12px] md:text-[18px] font-semibold">
                      User Name:{" "}
                      <span className="ml-2 font-normal">
                        {orderSummary.customername}
                      </span>
                    </p>
                    <p className="lg:w-[300px] xl:w-full w-full text-[12px] md:text-[18px] font-semibold mt-1">
                      Email:{" "}
                      <span className="ml-2 font-normal">
                        {orderSummary.email}
                      </span>
                    </p>
                    <p className="lg:w-[300px] xl:w-full w-full text-[12px] md:text-[18px] font-semibold mt-1">
                      Phone Number:{" "}
                      <span className="ml-2 font-normal">
                        {orderSummary.customernumber}
                      </span>
                    </p>
                    <p className="lg:w-[300px] xl:w-full w-full text-[12px] md:text-[18px] font-semibold mt-1">
                      Address:{" "}
                      <span className="ml-2 font-normal">
                        {orderSummary.shipaddress}
                      </span>
                    </p>
                  </div>
                </div>

                <div className="mt-3">
                  <div className="flex justify-between items-center text-[12px] md:text-[18px]">
                    <p className="text-[#212121]  font-semibold">
                      Total Count: {totalCount} Test(s)
                    </p>
                    {/* <p className="text-[#212121] text-[18px] font-semibold">Total Amount :<span className="text-[#008081] ml-2">{currency}<span className="ml-2">{totalAmount.toFixed(2)}</span></span></p> */}
                    <p className="text-[#212121]  font-semibold">
                      Total Amount :
                      <span className="text-[#008081] ml-2">
                        EUR
                        <span className="ml-2">{totalAmount.toFixed(2)}</span>
                      </span>
                    </p>
                  </div>
                  <div className="flex justify-between items-center mt-3 text-[12px] md:text-[18px]">
                    <p className="text-[#212121]  font-semibold">Tax (15%):</p>
                    {/* <p className="text-[#008081] text-[18px] font-semibold">  {currency}<span className="ml-2">{taxAmount.toFixed(2)}</span></p> */}
                    <p className="text-[#008081]  font-semibold">
                      {" "}
                      EUR <span className="ml-2">{taxAmount.toFixed(2)}</span>
                    </p>
                  </div>
                  <div className="flex justify-between items-center mt-3 text-[12px] md:text-[18px]">
                    <p className="text-[#212121]  font-semibold">Subtotal:</p>
                    {/* <p className="text-[#008081] text-[18px] font-semibold"> {currency}<span className="ml-2">{subTotal.toFixed(2)}</span></p> */}
                    <p className="text-[#008081]  font-semibold">
                      EUR
                      <span className="ml-2">{subTotal.toFixed(2)}</span>
                    </p>
                  </div>
                </div>
                <Link
                  to="/paymentcard"
                  state={{
                    totalAmount: totalAmount.toFixed(2),
                    currency,
                    taxAmount: taxAmount.toFixed(2),
                    subTotal: subTotal.toFixed(2),
                    orderSummary,
                  }}
                >
                  <button className="bg-[#008081] hover:bg-[#006e73] text-white font-medium py-2 px-4 rounded mt-5 w-full">
                    Proceed to Payment
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderSummary;
