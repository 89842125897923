import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Register({ GotoSignin }) {
  const baseUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEV
    : process.env.REACT_APP_BASE_URL_PROD;


  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [data, setData] = useState({
    FullName: "",
    number: "",
    email: "",
    createPassword: "",
    confirmPassword: "",
  });

  const [error, setError] = useState("");

  const handleChange = ({ currentTarget: input }) => {
    setData({
      ...data,
      [input.name]:
        input.name === "email" ? input.value.toLowerCase() : input.value,
    });
  };

  const handleChange1 = (value) => {
    setData({ ...data, number: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError("");
      const url = `${baseUrl}/users/register`;
      const { data: res } = await axios.post(url, data);
      if (res.success) {
        setRegistrationSuccess(true);
      } else {
        setError(res.message);
      }
    } catch (err) {
      if (
        err.response &&
        err.response.status >= 400 &&
        err.response.status <= 500
      ) {
        setError(err.response.data.message);
      }
    }
  };

  useEffect(() => {
    if (registrationSuccess) {
      const timer = setTimeout(() => {
        setRegistrationSuccess(false);
        GotoSignin();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [registrationSuccess]);

  return (
    <>
      <div className="">
        <form onSubmit={handleSubmit}>
          <p className="md:text-[48px] text-[34px] font-[700] text-[#444444] md:py-2 vol">
            Register your
            <span className="text-[#008081] ml-2 vol">account</span>
          </p>
          <input
            type="text"
            name="FullName"
            value={data.FullName}
            onChange={handleChange}
            required
            placeholder="Enter your FullName "
            className="w-full h-[48px] border border-[#DDDDDD] text-[#ADA4A5] text-[12px] pl-2 rounded-[10px] mb-2"
          />
          <PhoneInput
            countryCodeEditable={true}
            enableSearch
            country="de"
            type="text"
            id="mobile"
            className="w-full h-[48px] border rounded-[10px] !border-[#D6D6D6] indent-2 pt-2 mb-2"
            required
            placeholder="Enter your phone number"
            name="number"
            value={data.number}
            onChange={handleChange1}
          />
           <input
            type="text"
            name="address"
            // value={data.email}
            // onChange={handleChange}
            // required
            placeholder="Enter your Address"
            className="w-full h-[48px] border border-[#DDDDDD] text-[#ADA4A5] text-[12px] pl-2 rounded-[10px] mb-2"
          />

          <input
            type="email"
            name="email"
            value={data.email}
            onChange={handleChange}
            required
            placeholder="Enter your Email address"
            className="w-full h-[48px] border border-[#DDDDDD] text-[#ADA4A5] text-[12px] pl-2 rounded-[10px] mb-2"
          />
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              name="createPassword"
              value={data.createPassword}
              onChange={handleChange}
              required
              placeholder="Enter your Password"
              className="w-full h-[48px] border border-[#DDDDDD] text-[#ADA4A5] text-[12px] pl-2 rounded-[10px] mb-2"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute inset-y-0 right-0 px-2 -top-2"
            >
              {showPassword ? (
                <span class="material-symbols-outlined !text-[16px] mt-4">
                  visibility
                </span>
              ) : (
                <span class="material-symbols-outlined !text-[16px] mt-4">
                  visibility_off
                </span>
              )}
            </button>
          </div>

          <div className="relative">
            <input
              type={showPassword1 ? "text" : "password"}
              name="confirmPassword"
              value={data.confirmPassword}
              onChange={handleChange}
              required
              placeholder="Confirm Password"
              className="w-full h-[48px] border border-[#DDDDDD] text-[#ADA4A5] text-[12px] pl-2 rounded-[10px] mb-2"
            />
            <button
              type="button"
              onClick={() => setShowPassword1(!showPassword1)}
              className="absolute inset-y-0 right-0 px-2 -top-2"
            >
              {showPassword1 ? (
                <span class="material-symbols-outlined !text-[16px] mt-4">
                  visibility
                </span>
              ) : (
                <span class="material-symbols-outlined !text-[16px] mt-4">
                  visibility_off
                </span>
              )}
            </button>
          </div>

          {/* <div className="flex py-2">
            <hr className="w-full mt-3" />
            <p className="px-3">Or</p>
            <hr className="w-full mt-3" />
          </div> */}

          {/* <div className="grid justify-items-center">
            <button className="border flex justify-center items-center md:w-[350px] w-[280px] h-[50px] rounded-[14px] mb-5">
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/color/48/google-logo.png"
                alt="google-logo"
              />
              <p className="flex ml-2">Continue with Google Account</p>
            </button>
            <button className="border flex justify-center items-center md:w-[350px] w-[280px] h-[50px] rounded-[14px] mb-5">
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/color/48/facebook-new.png"
                alt="facebook-new"
              />
              <p className="flex ml-2">Continue with Facebook Account</p>
            </button>
          </div> */}
          <div className="flex justify-center">
            <button className="w-[120px] h-[40px] bg-[#008081] text-white rounded-[4px] mb-5">
              Register Now
            </button>
          </div>
        </form>
        {error && <p className="error-message text-red-600">{error}</p>}
      </div>
    </>
  );
}

export default Register;
