import React, { useState, useEffect } from "react";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";

const libraries = ["places"];

const MapComponent = ({
  onLocationSelect,
  initialLocation,
  initialAddress,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyBX1DTYdKXFIiSEmEBsCePtMYllSlUMbAw",
    libraries,
  });

  const [autocomplete, setAutocomplete] = useState(null);
  const [inputText, setInputText] = useState("");

  useEffect(() => {
    setInputText(initialAddress);
    // window.location.reload();
  }, [initialAddress]);

  // const onPlaceChanged = () => {
  //   if (autocomplete !== null) {
  //     const place = autocomplete.getPlace();
  //     if (place.geometry && place.geometry.location) {
  //       const location = {
  //         lat: place.geometry.location.lat(),
  //         lng: place.geometry.location.lng(),
  //       };

  //       // Notify parent component with new location and address
  //       onLocationSelect({
  //         lat: location.lat,
  //         lng: location.lng,
  //         address: place.formatted_address,
  //       });

  //       // Update local state
  //       setInputText(place.formatted_address);
  //       window.location.reload();
  //     }
  //   }
  // };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
  
      // Check if place and geometry are defined
      if (place && place.geometry && place.geometry.location) {
        const location = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
  
        // Notify parent component with new location and address
        onLocationSelect({
          lat: location.lat,
          lng: location.lng,
          address: place.formatted_address,
        });
  
        // Update local state
        setInputText(place.formatted_address);
      } else {
        // If place or geometry is invalid
        console.error("Invalid place selected. Please choose a valid location.");
        alert("Please select a valid location from the dropdown.");
      }
    } else {
      console.error("Autocomplete is not initialized.");
      alert("Autocomplete is not initialized. Try again.");
    }
  };
  
  

  if (loadError) {
    return <p>Error loading map</p>;
  }

  return (
    <div className="flex justify-center items-center">
      {isLoaded ? (
        <div className="text-[#008081] rounded-t-[8px] font-semibold">
          <div className="relative">
            <Autocomplete
              onLoad={(autocompleteInstance) =>
                setAutocomplete(autocompleteInstance)
              }
            >
              <div className="flex items-center justify-center md:p-5">
                <div className="rounded-lg border h-[30.5px] md:h-full border-[#008081]">
                  <div className="flex">
                    <div className="flex md:w-10 items-center justify-center rounded-tl-lg rounded-bl-lg border-r border-gray-200 bg-white p-3 md:p-5">
                      <svg
                        viewBox="0 0 30 30"
                        aria-hidden="true"
                        className="pointer-events-none absolute w-[20px] md:w-10 mt-2 ml-1 md:mt-3 md:ml-3  fill-gray-500 transition"
                      >
                        <path d="M16.72 17.78a.75.75 0 1 0 1.06-1.06l-1.06 1.06ZM9 14.5A5.5 5.5 0 0 1 3.5 9H2a7 7 0 0 0 7 7v-1.5ZM3.5 9A5.5 5.5 0 0 1 9 3.5V2a7 7 0 0 0-7 7h1.5ZM9 3.5A5.5 5.5 0 0 1 14.5 9H16a7 7 0 0 0-7-7v1.5Zm3.89 10.45 3.83 3.83 1.06-1.06-3.83-3.83-1.06 1.06ZM14.5 9a5.48 5.48 0 0 1-1.61 3.89l1.06 1.06A6.98 6.98 0 0 0 16 9h-1.5Zm-1.61 3.89A5.48 5.48 0 0 1 9 14.5V16a6.98 6.98 0 0 0 4.95-2.05l-1.06-1.06Z"></path>
                      </svg>
                    </div>

                    <div className="flex items-center">
                      <input
                        type="text"
                        className="w-full max-w-[100px] md:max-w-[160px] bg-white pl-1 pr-1 text-base font-semibold outline-0"
                        placeholder=""
                        value={inputText}
                        onChange={(e) => setInputText(e.target.value)}
                      />
                    </div>

                    <div
                      onClick={onPlaceChanged}
                      className="button1 h-[17px] md:h-[40px] !rounded-[5px] cursor-pointer"
                    >
                      <p className="-mt-3 md:-mt-[6px]">Search</p>
                    </div>
                  </div>
                </div>
              </div>
            </Autocomplete>
          </div>
        </div>
      ) : (
        <p>Loading map...</p>
      )}
    </div>
  );
};

export default MapComponent;

{
  /* <input
                type="text"
                placeholder="Search your location"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                className="location-search-input text-[#008081]  rounded-[5px] w-[150px] md:w-[250px] pl-6 pr-2 border-2 border-black "
              /> */
}
