import React, { useEffect, useRef, useState } from "react";
import PhoneInput from 'react-phone-input-2';
import { Link } from "react-router-dom";
import Navbarr from "../navbar";


export default function Complete_Profile() {

  // Country select 
  const [selectedOption, setSelectedOption] = useState('');

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const data = [
    { value: 'option1', label: 'Saudi Arabia' },
    { value: 'option2', label: 'Australia' },
    { value: 'option3', label: 'india' },
    { value: 'option4', label: 'England' },
    { value: 'option5', label: 'Quaid' },
  ];

  // Contact Section 

  const [accordionOpen, setAccordionOpen] = useState(false);

  const CustomOption = ({ innerProps, label, data }) => (
    <div {...innerProps}>

      <span className="option-label">{label}<img src={data.image} alt={label} className="option-image" /></span>
      <span className="option-number"> ({data.number})</span>
    </div>
  );
  const options = [
    { value: 'apple', label: 'Apple', image: '../images/arab.png', number: 1 },
    { value: 'banana', label: 'Banana', image: '../images/arab.png', number: 2 },
    // Add more items as needed
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [valid, setValid] = useState(true);
  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;

    return phoneNumberPattern.test(phoneNumber);
  };

  //  Contact End 

  // Time 

  const [selectedButtons, setSelectedButtons] = useState([]);

  const handleButtonClick = (buttonId) => {
    // Check if the button is already selected
    if (selectedButtons.includes(buttonId)) {
      // If selected, remove it from the array
      setSelectedButtons(selectedButtons.filter((id) => id !== buttonId));
    } else {
      // If not selected, add it to the array
      setSelectedButtons([...selectedButtons, buttonId]);
    }
  };
  const min = [
    { m: '00' },
    { m: '01' },
    { m: '02' },
    { m: '03' },
    { m: '04' },
    { m: '05' },
    { m: '06' },
    { m: '07' },
    { m: '08' },
    { m: '09' },
    { m: '10' },

  ]


  // Add Photo 
  const inputref = useRef();
  const [image, SetImage] = useState("")

  const handleClick = () => {
    inputref.current.click();
  }

  const handleChange = (event) => {
    // const file = event.target.files[0];
    SetImage(event.target.files[0]);
  }




  return (
    <>
      <Navbarr />
      <div className="flex xl:px-[calc((100%-1250px)/2)] px-3 py-5">
        <p className="text-[#000000] text-[12px] md:text-[16px] mr-1 roboto">
          Home /
        </p>
        <p className="text-[#9E9E9E] text-[12px] md:text-[16px] roboto">
          Become Vendor /
        </p>
        <p className="text-[#9E9E9E] text-[12px] md:text-[16px] roboto">
          Complete Profile
        </p>
      </div>

      <div className="grid lg:grid-cols-2 lg:px-[calc((100%-1250px)/2)]">
        <div className="lg:w-[100%] px-3 lg:px-0">
          <p className="md:text-[48px] text-[34px] font-[700] text-[#444444] lg:w-[630px] md:py-5 vol">
            Create <br />
            <span className="text-[#008081] vol">business Listing
            </span>
          </p>
          <div className=" md:px-5">
            <input
              type="text"
              placeholder="Jim Pet Care Service"
              className=" mb-5 text-[#ADA4A5] roboto text-[12px] pl-5 w-full h-[48px] border-[#DDDDDD] border rounded-[10px]"
            />
            <input
              type="text"
              placeholder="Building No & Name"
              className=" mb-5 text-[#ADA4A5] roboto text-[12px] pl-5 w-full h-[48px] border-[#DDDDDD] border rounded-[10px]"
            />
            <input
              type="text"
              placeholder="Street Name"
              className=" mb-5 text-[#ADA4A5] roboto text-[12px] pl-5 w-full h-[48px] border-[#DDDDDD] border rounded-[10px]"
            />
            <input
              type="text"
              placeholder="Landmark"
              className="mb-5 text-[#ADA4A5] roboto text-[12px] pl-5 w-full h-[48px] border-[#DDDDDD] border rounded-[10px]"
            />
            <div className="flex gap-8">
              <div className="flex-1 relative flex items-center">
                <input
                  type="text"
                  placeholder="City"
                  className="mb-5 text-[#ADA4A5] roboto text-[12px] pl-5 w-full h-[48px] border-[#DDDDDD] border rounded-[10px]"
                />
                <div className="absolute right-0 mr-3 h-[48px] cursor-pointer mt-4">
                  <svg
                    className="h-4 w-4 text-[#ADA4A5]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </div>
              </div>
              <div className="flex-1 relative flex items-center">
                <input
                  type="text"
                  placeholder="State"
                  className="mb-5 text-[#ADA4A5] roboto text-[12px] pl-5 w-full h-[48px] border-[#DDDDDD] border rounded-[10px]"
                />
                <div className="absolute cursor-pointer right-0 mr-3 h-[48px] mt-4">
                  <svg
                    className="h-4 w-4 text-[#ADA4A5]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>

            <div className="">
              <select className="custom-dropdown mb-5 text-[#ADA4A5] roboto text-[12px] pl-5 w-full h-[48px] border-[#DDDDDD] border rounded-[10px]"
                id="myDropdown"
                value={selectedOption}
                onChange={handleSelectChange}
              >
                <option value="">Country</option>
                {data.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </select>

              {/* <p>Selected option: {selectedOption}</p> */}

            </div>
            <p className="text-[10px] font-[400] roboto text-[#ADA4A5] roboto mb-5 ">
              By continuing, you agree to our{" "}
              <span className="text-[#008081] roboto">
                Terms of Use, Privacy & Policy
              </span>
            </p>
            <Link to={'/addcontact'}>
              <button className="w-[173px] roboto h-[41px] bg-[#008081] text-[#ffffff] rounded-[4px]">
                Next
              </button>
            </Link>
          </div>
        </div>
        <div className="mt-10 px-5 mb-10">
          <img
            src="./images/sideimg.png"
            alt="new-post"
            className="text-[#999999]w-[400px] h-[550px]  md:w-[562px] md:h-[625px]"
          />
        </div>
      </div>
    </>
  );
}
