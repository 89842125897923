import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Cropper from "react-easy-crop";
import MapComponent from "../mapComponent";

function My_Profile() {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const [userInfo, setUserInfo] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [userName, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumer, setPhoneNumer] = useState("");
  const [address, setAddress] = useState("");
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [errorTimeout, setErrorTimeout] = useState(null);
  console.log(userInfo);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [file, setFile] = useState(null);
  const [images, setImages] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [profileImage, setProfileImage] = useState("");
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [croppedImageFile, setCroppedImageFile] = useState(null);
  const [showImagePopup, setShowImagePopup] = useState(false);

  const getuserdetails = () => {
    const token = localStorage.getItem("token");
    console.log(token, "token");

    try {
      fetch(`${baseUrl}/userInfo/getUserDetails`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Failed to fetch booking details");
          }
        })
        .then((data) => {
          console.log("sc", data.result);
          setUserInfo(data.result);
          setUsername(data.result.FullName);
          setEmail(data.result.email);
          setPhoneNumer(data.result.number);
          setAddress(data.result.userAddress);
        })
        .catch((error) => {
          console.error("Error fetching booking details:", error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getuserdetails();
    fetchProfileImage();
  }, []);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [message, setMessage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");
    const userInfo = jwtDecode(token);
    console.log(userInfo);
    if (!formData.newPassword || !formData.confirmPassword) {
      setMessage("Please enter both new password and confirm password.");
      return;
    }
    try {
      setError("");
      const { currentPassword, newPassword, confirmPassword } = formData;

      const response = await axios.post(
        `${baseUrl}/users/changePassword?email=${userInfo.email}`,
        {
          currentPassword,
          newPassword,
          confirmPassword,
        }
      );

      if (response.data.isSuccess) {
        setMessage(response.data.message);
        setPasswordUpdated(true);
        setTimeout(() => {
          setMessage("");
          setPasswordUpdated(false);
          setFormData({
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
        }, 3000);
      } else {
        setSuccess(response.data.message);
      }
    } catch (err) {
      if (
        err.response &&
        err.response.status >= 400 &&
        err.response.status <= 500
      ) {
        setError(err.response.data.message);
        setErrorTimeout(
          setTimeout(() => {
            setError("");
          }, 3000)
        );
      } else {
        setError("Internal Server Error");
        setErrorTimeout(
          setTimeout(() => {
            setError("");
          }, 3000)
        );
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleProfileAction = async (e) => {
    const token = localStorage.getItem("token");

    if (isEditing) {
      setIsEditing(!isEditing);
      try {
        const data = {
          FullName: userName,
          email: email,
          number: phoneNumer,
          userAddress: address,
        };

        const response = await axios.put(`${baseUrl}/users/edit`, data, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        });

        if (response.data) {
          setIsEditing(!isEditing);
          setMessage("User details updated successfully");
          setTimeout(() => {
            setMessage("");
          }, 3000);
        }
      } catch (error) {
        console.error("Error updating user info:", error);
        setMessage("Failed to update user info");
      }
    } else {
      setIsEditing(true);
    }
  };

  const handleViewImage = () => {
    setShowImagePopup(true);
  };

  const closeImagePopup = () => {
    setShowImagePopup(false);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, "image/jpeg");
    });
  };

  const handleCrop = async () => {
    if (!imageSrc || !croppedAreaPixels) {
      alert("Please select an image and crop it first");
      return;
    }
    try {
      const croppedImageBlob = await getCroppedImg(imageSrc, croppedAreaPixels);

      const file = new File([croppedImageBlob], "cropped_image.jpg", {
        type: "image/jpeg",
      });

      const croppedUrl = URL.createObjectURL(croppedImageBlob);
      setCroppedImageUrl(croppedUrl);
      setCroppedImageFile(file);

      // alert("Image cropped successfully!");
    } catch (error) {
      console.error("Error cropping image:", error);
      alert("Error cropping image. Please try again.");
    }
  };

  const token = localStorage.getItem("token");
  const handleUpload = async () => {
    if (!croppedImageFile) {
      // alert("Please crop an image first");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("image", croppedImageFile);

      const apiResponse = await axios.post(
        `${baseUrl}/users/upload`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (apiResponse.status === 200) {
        const newImage = {
          filename: croppedImageFile.name,
          path: apiResponse.data.imageUrl,
        };

        await fetchProfileImage();

        setImages(newImage);
        setShowPopup(false);
        setImageSrc(null);
        setCroppedAreaPixels(null);
        setCroppedImageUrl(null);
        setCroppedImageFile(null);
        window.location.reload();
        // alert("uploaded successfully");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading image, please try again.");
    }
  };

  const fetchProfileImage = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const response = await axios.get(`${baseUrl}/users/getprofile`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        setProfileImage(response.data?.path);
      } catch (error) {
        console.error("Error fetching profile image:", error);
      }
    }
  };

  return (
    <>
      <div className="pb-10 animate__animated animate__bounceInRight">
        <p className="text-[20px] text-[#008081] font-[500] text-center md:text-start mt-5">
          My Profile
        </p>
        <div className=" md:py-10">
          <div className="flex items-center text-[#008081] text-[20px] font-[500]">
            <img
              src={
                profileImage
                  ? `http://localhost:4000/${profileImage}`
                  : "images/userimage.jpg"
              }
              alt="Profile"
              className="rounded-full w-[70px] h-[70px] cursor-pointer"
              onClick={handleViewImage}
            />

            {showImagePopup && (
              <div
                className="fixed inset-0 bg-transparent z-50 flex items-center justify-center h-[40%] md:h-full`"
                onClick={closeImagePopup}
              >
                <div
                  className="relative bg-white p-1 rounded-lg border-[5px] border-[#008081]"
                  onClick={(e) => e.stopPropagation()}
                >
                  <img
                    src={
                      profileImage
                        ? `http://localhost:4000/${profileImage}`
                        : "images/userimage.jpg"
                    }
                    alt="Profile"
                    className="max-w-full max-h-[50vh] object-contain"
                  />
                  <button
                    className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded shadow-lg"
                    onClick={closeImagePopup}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}

            {imageSrc && (
              <div className="fixed inset-0  z-[1000] flex items-center justify-center h-[350px]">
                <div className="relative w-full max-w-md h-[80vh] bg-white rounded-lg  z-[1010]">
                  <Cropper
                    image={imageSrc}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                  />
                  <div className="absolute bottom-4 left-0 right-0 flex justify-center space-x-4">
                    <button
                      onClick={handleCrop}
                      className="bg-[#008081] text-white px-4 py-2 rounded"
                    >
                      Crop
                    </button>
                    {croppedImageUrl && (
                      <button
                        onClick={handleUpload}
                        className="bg-[#008081] text-white px-4 py-2 rounded"
                      >
                        Upload
                      </button>
                    )}
                    <button
                      onClick={() => setImageSrc(null)}
                      className="bg-red-500 text-white px-4 py-2 rounded"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
            <p className="ml-2">{userName}</p>
          </div>
          <div className="mt-3 md:mt-0 md:float-right">
            <div className="flex ">
              <label
                htmlFor="fileInput"
                className="w-[90px] flex justify-center items-center md:w-[140px] h-[30px] md:h-[48px] text-[10px] md:text-[14px]  font-[400] text-[#ffffff] bg-[#008081] hover:bg-[#0a4747] border-[1px] md:ml-10 rounded-[8px]"
              >
                Upload Photo
                <input
                  id="fileInput"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleImageChange}
                />
              </label>

              <button
                className="w-[90px]  md:w-[140px] h-[30px] md:h-[48px] text-[10px] md:text-[14px]  font-[400] text-[#008081] border-[#008081] border-[1px] ml-10 rounded-[8px]"
                onClick={handleProfileAction}
              >
                {isEditing ? "Save Profile" : "Edit Profile"}
              </button>
            </div>
          </div>
        </div>
        <div className="mt-5 md:mt-0">
          <p className="text-[20px] text-[#008081] font-[400]">
            Personal Information
          </p>
          <hr className="w-full my-2 md:my-5" />
          {message && (
            <div className="flex justify-center">
              <p className="bg-green-100 w-[50%] text-center text-green-800 p-3 rounded-lg mb-5">
                {message}
              </p>{" "}
            </div>
          )}
          <div className="md:flex md:gap-10">
            <div className="md:w-[50%] mt-3">
              <p className="text-[14px] text-[#008081] font-[400]">Full Name</p>
              {isEditing ? (
                <input
                  type="text"
                  className="w-full h-[48px] rounded-[8px] border-[1px] text-[#787878] pl-4 mt-3 "
                  placeholder="Enter your Full Name"
                  value={userName}
                  name="userName"
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                />
              ) : (
                <p className="mt-3">{userName}</p>
              )}
            </div>
            <div className="md:w-[50%] mt-3">
              <p className="text-[14px] text-[#008081] font-[400]">
                Phone Number
              </p>
              {isEditing ? (
                <input
                  type="text"
                  className="w-full h-[48px] rounded-[8px] border-[1px] text-[#787878] pl-4 mt-3"
                  placeholder="Enter your Phone Number"
                  value={phoneNumer}
                  name="phoneNumer"
                  onChange={(e) => {
                    setPhoneNumer(e.target.value);
                  }}
                />
              ) : (
                <p className="mt-3">{phoneNumer}</p>
              )}
            </div>
          </div>

          <div className="md:flex gap-10">
            <div className="md:w-[50%] mr-5 mt-3">
              <p className="text-[14px] text-[#008081] font-[400]">Address</p>
              {isEditing ? (
                <input
                  type="text"
                  className="w-full h-[48px] rounded-[8px] border-[1px] text-[#787878] pl-4 mt-3"
                  placeholder="Enter your Address"
                  value={address}
                  name="address"
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
              ) : (
                <p className="mt-3">{address}</p>
              )}
            </div>
            <div className="md:w-[50%] mr-5 mt-3">
              <p className="text-[14px] text-[#008081] font-[400]">
                Email Address
              </p>
              <p className="mt-2 break-all" style={{ cursor: "not-allowed" }}>
                {email}
              </p>
            </div>
          </div>

          <div>
            <p className="text-[20px] text-[#008081] font-[400] mt-5">
              Password Change
            </p>
            <hr className="w-full my-2 md:my-5" />
            <form>
              <div className="md:flex justify-between mt-3">
                <div className="md:w-[50%] md:mr-5">
                  <p className="text-[14px] text-[#008081] font-[400]">
                    Current Password
                  </p>

                  <div className="relative ">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="currentPassword"
                      value={formData.currentPassword}
                      onChange={handleChange}
                      required
                      placeholder="Current Password"
                      className="w-full h-[48px] rounded-[8px] border-[1px] text-[#787878] pl-4 mt-3"
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute inset-y-0 right-0 px-2 top-5"
                    >
                      {showPassword ? (
                        <span class="material-symbols-outlined !text-[16px] ">
                          visibility
                        </span>
                      ) : (
                        <span class="material-symbols-outlined !text-[16px] ">
                          visibility_off
                        </span>
                      )}
                    </button>
                  </div>
                </div>
                <div className="md:w-[50%]">
                  <p className="text-[14px] text-[#008081] font-[400]">
                    New Password
                  </p>
                  <div className="relative ">
                    <input
                      type={showPassword1 ? "text" : "password"}
                      name="newPassword"
                      value={formData.newPassword}
                      onChange={handleChange}
                      required
                      placeholder="New Password"
                      className="w-full h-[48px] rounded-[8px] border-[1px] text-[#787878] pl-4 mt-3"
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword1(!showPassword1)}
                      className="absolute inset-y-0 right-0 px-2 top-5"
                    >
                      {showPassword1 ? (
                        <span class="material-symbols-outlined !text-[16px] ">
                          visibility
                        </span>
                      ) : (
                        <span class="material-symbols-outlined !text-[16px] ">
                          visibility_off
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>

              <div className="md:flex lg:justify-between mt-3">
                <div className="md:w-[50%] md:mr-5">
                  <p className="text-[14px] text-[#008081] font-[400]">
                    Confirm New Password
                  </p>
                  <div className="relative ">
                    <input
                      type={showPassword2 ? "text" : "password"}
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      required
                      placeholder="Confirm New Password"
                      className="w-full  h-[48px] rounded-[8px] border-[1px] text-[#787878] pl-4 mt-3"
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword2(!showPassword2)}
                      className="absolute inset-y-0 right-0 px-2 top-5"
                    >
                      {showPassword2 ? (
                        <span class="material-symbols-outlined !text-[16px] ">
                          visibility
                        </span>
                      ) : (
                        <span class="material-symbols-outlined !text-[16px] ">
                          visibility_off
                        </span>
                      )}
                    </button>
                  </div>
                </div>
                <div className="lg:w-[50%] mt-8">
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="w-[120px] h-[30px] md:w-[140px] md:h-[48px] text-[10px] md:text-[14px] font-[400] text-[#ffffff] bg-[#008081] rounded-[8px]"
                  >
                    Update Password{" "}
                  </button>
                </div>
              </div>
            </form>

            {error && (
              <div className="flex justify-center">
                <p className="bg-red-400 text-center p-3 mt-3">{error}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default My_Profile;
