import React, { useState, useEffect, useRef } from "react";
import My_Profile from "../My profile";

const MyLeads = () => {
    const itemsPerPage = 4;
    const [currentPage, setCurrentPage] = useState(1);
    const [currentStatus, setCurrentStatus] = useState("");


    const data = [
        {
            date: '20-01-2024',
            businessName: 'Ahamed Ali',
            mail: 'ahamedali@gmail.com',
            cost: '250 AED',
            contact: '+971 54 250 6091',
            status: 'Booked',
            pay: 'Paid'
        },
        {
            date: '20-01-2024',
            businessName: 'Ahamed Ali',
            mail: 'ahamedali@gmail.com',
            cost: '250 AED',
            contact: '+971 54 250 6091',
            status: 'Booked',
            pay: 'Not Paid'
        },
        {
            date: '20-01-2024',
            businessName: 'Ahamed Ali',
            mail: 'ahamedali@gmail.com',
            cost: '250 AED',
            contact: '+971 54 250 6091',
            status: 'Completed',
            pay: 'Paid'
        },
        {
            date: '20-01-2024',
            businessName: 'Ahamed Ali',
            mail: 'ahamedali@gmail.com',
            cost: '250 AED',
            contact: '+971 54 250 6091',
            status: 'Completed',
            pay: 'Not Paid'
        },
        {
            date: '20-01-2024',
            businessName: 'Ahamed Ali',
            mail: 'ahamedali@gmail.com',
            cost: '250 AED',
            contact: '+971 54 250 6091',
            status: 'Cancelled',
            pay: 'Not Paid'
        },
        {
            date: '20-01-2024',
            businessName: 'Ahamed Ali',
            mail: 'ahamedali@gmail.com',
            cost: '250 AED',
            contact: '+971 54 250 6091',
            status: 'Cancelled',
            pay: 'Not Paid'
        },
    ];



    const filteredData = currentStatus
        ? data.filter((item) => item.status === currentStatus)
        : data;

    const totalFilteredItems = filteredData.length;
    const totalPages = Math.ceil(totalFilteredItems / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    // const endIndex = Math.min(startIndex + itemsPerPage, totalFilteredItems);
    const currentData = filteredData

    const handleStatusChange = (event) => {
        setCurrentStatus(event.target.value);
        setCurrentPage(1);
    };

    const goToPrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const goToNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const [openedDropdownId, setOpenedDropdownId] = useState(-1);

    const toggleDropdown = (index) => {
        setOpenedDropdownId(openedDropdownId === index ? -1 : index);
    };


    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpenedDropdownId(-1);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const headers = ['Booked Date', 'Full Name', 'Email Address', 'Cost', 'Contact No', 'Status', 'Payment Status'];

    const tableData = [
        ['20-01-2024', 'Ahamed Ali', 'ahamedali@gmail.com', '250 AED', '+971 54 250 6091', 'F1', 'G1'],
        ['20-01-2024', 'Ahamed Ali', 'ahamedali@gmail.com', '250 AED', '+971 54 250 6091', 'F2', 'G2'],
        ['20-01-2024', 'Ahamed Ali', 'ahamedali@gmail.com', '250 AED', '+971 54 250 6091', 'F3', 'G3'],
        ['20-01-2024', 'Farook', 'farook@gmail.com ', '250 AED', '+971 54 250 6091', 'F4', 'G4'],
        ['20-01-2024', 'Mohammed', 'farook@gmail.com ', '250 AED', '+971 54 250 6091', 'F5', 'G5'],
        ['20-01-2024', 'Mohammed Essa ', 'essa@gmail.com', '+971 54 250 6091', 'F6', 'G6'],
    ];
    return (
        <>
            <div className="flex justify-between">
                <p className="text-[20px] text-[#008081] font-[400]">My Leads</p>
                <div className=" border rounded-[10px] w-[133px] h-[40px] grid place-content-center bg-[#D5D9DD] bg-opacity-[20%] border-[1px] border-[#D5D9DD]">
                    <div className="flex ">
                        <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.5556 2.44458H2.44444C1.6467 2.44458 1 3.09128 1 3.88902V14.0001C1 14.7979 1.6467 15.4446 2.44444 15.4446H12.5556C13.3533 15.4446 14 14.7979 14 14.0001V3.88902C14 3.09128 13.3533 2.44458 12.5556 2.44458Z" stroke="#9295AB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.3889 1V3.88889M4.61111 1V3.88889M1 6.77778H14" stroke="#9295AB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <p className="text-[#787878] text-[14px] ml-2">10 Jan 2024</p>
                    </div>
                </div>
            </div>
            {/* <div className="overflow-x-auto">
                <table className="min-w-full table-auto mt-5">
                    <thead>
                        <tr>
                            {headers.map((header, index) => (
                                <th key={index} className="border-t-[2px] border-b-[2px] py-5 px-4 text-[14px] font-[500] text-[#8C90A6]">
                                    {header}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {row.map((cell, cellIndex) => (
                                    <td key={cellIndex} className="border-gray-400 border-b-[1px] py-2 text-center text-[#8C90A6] py-5">
                                        {cell}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div> */}

            <div className="max-w-full overflow-x-auto mb-10">
                <div className="max-w-full mt-5 rounded-sm border-l-stroke border-r-stroke bg-white shadow-default dark:border-t-strokedark border-b-[#CFD4D9]  border-b-2 dark:bg-boxdark overflow-x-auto">



                    <table className="w-full table-auto">
                        <thead>
                            <tr>
                                {headers.map((header, index) => (
                                    <th key={index} className="border-t-[2px] border-b-[2px] py-5 px-4 text-[14px] font-[500] text-[#8C90A6]">
                                        {header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {currentData.map((item, index) => (
                                <tr key={index} className="">
                                    <td className=" border-[#787878]-400 border-b-[1px] py-2 text-center text-[#8C90A6] py-5">
                                        <h5 className="">
                                            {item.date}
                                        </h5>
                                    </td>
                                    <td className=" border-[#787878]-400 border-b-[1px] py-2 text-center text-[#8C90A6] py-5 ">
                                        <p className="">{item.businessName}</p>
                                    </td>
                                    <td className=" border-[#787878]-400 border-b-[1px] py-2 text-center text-[#8C90A6] py-5 ">
                                        <p className="">{item.mail}</p>
                                    </td>
                                    <td className="border-[#787878]-400 border-b-[1px] py-2 text-center text-[#8C90A6] py-5">
                                        <p className="">{item.cost}</p>
                                    </td>
                                    <td className="border-[#787878]-400 border-b-[1px] py-2 text-center text-[#8C90A6] py-5">
                                        <p className="">{item.contact}</p>
                                    </td>
                                    <td className="border-[#787878]-400 border-b-[1px] py-2 text-center text-[#8C90A6] py-5">
                                        <p
                                            className={`inline-flex rounded-[6px] py-1 px-8 text-sm font-medium ${item.status === 'Completed'
                                                ? 'bg-[#B6F8C7] text-[#1A8537]'
                                                : item.status === 'Booked'
                                                    ? 'bg-[#FAC9A5] text-[#FF6F07]'
                                                    : item.status === 'Cancelled'
                                                        ? 'bg-[#FFE5EE] text-[#AA0000]'
                                                        : ''
                                                }`}
                                        >
                                            {item.status}
                                        </p>

                                    </td>
                                    <td className="border-gray-400 border-b-[1px] py-2 text-center text-[#8C90A6] py-5">
                                        <p
                                            className={`inline-flex rounded-[6px] py-1 px-8 text-sm font-medium 
                                            ${item.pay === 'Paid' ? 'bg-[#B6F8C7] text-[#27AE60]'
                                                    : item.pay === 'Not Paid'
                                                        ? 'bg-[#FFE5EE] text-[#AA0000]'
                                                        : ''
                                                }`}
                                        >
                                            {item.pay}
                                        </p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>


                </div>

                {/* <div className="flex mt-5 items-center justify-end px-[84px] py-2 gap-6">

                    <div className="text-[#000000]">
                        {startIndex + 1} to {endIndex} of {totalFilteredItems}        </div>

                    <button className="text-[#000000]" onClick={goToPrevPage} disabled={currentPage === 1}>
                        &lt;
                    </button>
                    <button className="text-[#000000]" onClick={goToNextPage} disabled={currentPage === totalPages}>
                        &gt;
                    </button>
                </div> */}
            </div>
        </>
    )
}
export default MyLeads;