import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Navbarr from '../navbar';


function Add_Photo() {
    useEffect(()=>{
        window.scroll(0,0);
    },[])
    const [showDiv, setShowDiv] = useState(false);
    
    const ListAdded = () => {

        setShowDiv(true);
        const hideTimeout = setTimeout(() => {
            setShowDiv(false);
            clearTimeout(hideTimeout);
            window.location.href = '/my-business';
        }, 5000);

    }

    const inputref = useRef();
    const [image, SetImage] = useState("")

    const handleClick = () => {
        inputref.current.click();
    }

    const handleChange = (event) => {
        // const file = event.target.files[0];
        SetImage(event.target.files[0]);
    }

    return (
        <div>
            <Navbarr />
            <div className="flex xl:px-[calc((100%-1250px)/2)] px-3 py-5">
                <p className="text-[#000000] text-[12px] md:text-[16px] mr-1 roboto">
                    Home /
                </p>
                <p className="text-[#9E9E9E] text-[12px] md:text-[16px] roboto">
                    Become Vendor /
                </p>
                <p className="text-[#9E9E9E] text-[12px] md:text-[16px] roboto">
                    {" "}
                    Complete Profile
                </p>
            </div>
            <div className="lg:flex px-3 xl:px-[calc((100%-1250px)/2)] mb-20">
                <div className="md:w-[58%]">
                    <p className="md:text-[48px] text-[34px] font-[700] text-[#444444] md:py-5 vol">
                        Add <span className="text-[#008081] vol">photos</span>
                    </p>
                    <p className="text-[20px] font-[400] pb-5 pop">
                        Showcase photos of your business
                    </p>
                    <div className="md:h-[250px] md:w-[490px] w-[300px] h-[150px] border grid place-content-center" onClick={handleClick}>

                        {image ? (
                            <img src={URL.createObjectURL(image)} alt="" />
                        ) : (<img src="../images/addimage.png" alt="" className='' />)}
                        <input type="file" ref={inputref} onChange={handleChange} style={{ display: "none" }} multiple />
                    </div>
                    <div className="flex ">
                        <Link to={'/'}>
                            <p className="w-[93px] roboto h-[41px] bg-[#ffffff] mt-7 text-[#008081] rounded-[4px] my-5">Skip Now</p>
                        </Link>
                        <Link to={'/add_cost'}>
                            <button className="w-[173px] roboto h-[41px] bg-[#ffffff] border-[#008081] border-[2px] text-[#008081] rounded-[4px] my-5">
                                Previous
                            </button>
                        </Link>
                        <Link>
                            <button onClick={ListAdded} className="w-[173px] ml-2 roboto h-[41px] bg-[#008081] text-[#ffffff] rounded-[4px] my-5">
                                Next
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="lg:w-[50%] grid justify-items-center">
                    <img src="../images/add_photo.png" alt="" />
                </div>
            </div>
            {showDiv && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50 overflow-auto">
                    <div className="absolute justify-center md:p-10 p-5 bg-white w-[300px] mt-[00px] h-[200px] md:h-[300px]  md:w-[500px] lg:w-[790px] border rounded-[10px] shadow-md">
                        <div className="flex justify-center md:mb-4 mb-2">
                            <img src="../images/verify.png" alt="" />
                        </div>

                        <div className="flex mt-2 ">
                            <p className="md:text-[36px] text-[22px] font-[600] text-[#008081] text-center w-full">Verify Your Account</p>
                        </div>
                        <p className='md:text-[24px] font-[400] leading-8 text-[#666666] md:mt-5 mt-2 text-center w-full'>Please confirm your registered email address</p>
                    </div>

                </div>
            )
            }

        </div>
    )
}

export default Add_Photo