import React, { useEffect } from 'react'
import Navbarr from '../navbar'

export default function About() {
    useEffect(() => {
        window.scroll(0, 0);
    })
    return (
        <>
            <Navbarr />
            <div className="h-screen">
                <p className="">About</p>
            </div>
        </>
    )
}
