import { useEffect, useRef, useState } from "react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import Navbarr from "../navbar";
import { useCurrencyStore } from "../../store/store";
import "react-calendar/dist/Calendar.css";
import Popup from "../popup";

const Categories = () => {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  // const [accordionOpen, setAccordionOpen] = useState(false);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //       setAccordionOpen(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let query = searchParams.get("id");

  const [serviceList, setServiceList] = useState([]);
  const [category, setCategory] = useState(query);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [currency, currencyRate] = useCurrencyStore((state) => [
    state.currency,
    state.currencyRate,
  ]);

  const serviceProductInfo = () => {
    const storedLatitude = localStorage.getItem("latitude");
    const storedLongitude = localStorage.getItem("longitude");

    fetch(
      `${baseUrl}/home/categoryById?id=${query}&latitude=${storedLatitude}&longitude=${storedLongitude}`
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Unable to fetch data");
        }
      })
      .then((data) => {
        setServiceList(data.services);
        console.log(data,"catog")
        setCategoryList(data.categoryList);

        // alert(data.categoryId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    query = category;
    serviceProductInfo();
  }, [category]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [popup, setPopup] = useState(false);
  const togglePopup = () => {
    setPopup(!popup);
    if (!popup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  const [serviceId, setServiceId] = useState("");

  const [serviceInfo, setServiceInfo] = useState(null);

  useEffect(() => {
    fetch(`${baseUrl}/home/serviceById?id=${serviceId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data,"nnn");
        setServiceInfo(data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, [serviceId]);

  const Back = () => {
    window.history.back();
  };

  // popup Open details
  const [details, setDetails] = useState(false);
  const [id, setId] = useState(0);
  const OpenPopup = (id) => {
    setDetails(!details);
    setId(id);
  };
  const Closepopup = () => {
    setDetails(!details);
  };

  const [accordionOpen, setAccordionOpen] = useState(false);
  const accordionRef = useRef(null);
  const dropdownRef = useRef(null);

  // Close accordion when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        accordionRef.current &&
        !accordionRef.current.contains(event.target)
      ) {
        setAccordionOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div className="hidden md:block">
        <Navbarr />
      </div>
      {/* <div className="xl:px-[calc((100%-1250px)/2)] py-5 px-3 hidden md:flex">
        <p className="text-[#000000]">Home</p>/{" "}
        <p className="text-[#000000]">search</p>/{" "}
        <p className="text-[#9E9E9E]"> All Categories</p>
      </div> */}
      <div className=" mt-5 hidden lg:flex xl:px-[calc((100%-1250px)/2)] px-3 md:py-5">
        <Link className="flex gap-2 cursor-pointer" to="/">
          <span class="material-symbols-outlined">arrow_back</span>
          <p className="text-[#444444] text-[12px] md:text-[16px] mr-1">Back</p>
        </Link>
      </div>
      {/* desktop  popup*/}
      <div className="xl:px-[calc((100%-1250px)/2)] px-3 md:flex justify-between mt-4 hidden">
        <div className="flex items-center">
          <div className="md:hidden">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.25 12.2754L19.25 12.2754"
                stroke="#130F26"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.2988 18.299L4.24883 12.275L10.2988 6.25"
                stroke="#130F26"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <p className="md:text-[32px] pt-2 md:pt-0 text-[16px] h-10 text-[#1F1F1F] ml-1 md:ml- animate__animated animate__zoomIn ">
            Listings
          </p>
        </div>
        <div className="relative " ref={dropdownRef}>
          <div className="md:w-[285px] bg-[#ffffff] h-full z-10 absolute right-0 drop-shadow-xl animate__animated animate__jackInTheBox">
            <div className="bg-white" ref={accordionRef}>
              {/* Accordion button */}
              <button
                onClick={() => setAccordionOpen(!accordionOpen)}
                className="flex justify-between w-full px-3"
              >
                <div className=" items-center h-[30px] w-full flex justify-between mt-2 ">
                  <div className="flex items-center">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.211604 1.61C2.5316 4.59 5.9616 9 5.9616 9V14C5.9616 15.1 6.8616 16 7.9616 16C9.0616 16 9.9616 15.1 9.9616 14V9C9.9616 9 13.3916 4.59 15.7116 1.61C16.2216 0.95 15.7516 0 14.9116 0H1.0016C0.171604 0 -0.298396 0.95 0.211604 1.61Z"
                        fill="#008081"
                      />
                    </svg>
                    <p className="pl-2 text-[#212121] text-[14px] hidden md:block">
                      Filter
                    </p>
                  </div>
                </div>
                <img
                  src="../images/up.png"
                  alt="Toggle Accordion"
                  className={`mr-2 mt-5 transform origin-center rotate-0 transition duration-200 ease-out ${
                    accordionOpen && "!rotate-180"
                  }`}
                />
              </button>

              {/* Accordion content */}
              <div
                className={`grid px-3 overflow-hidden  transition-all duration-300 ease-in-out text-slate-600 text-sm ${
                  accordionOpen
                    ? "grid-rows-[1fr] opacity-100"
                    : "grid-rows-[0fr] opacity-0"
                }`}
              >
                <div className="overflow-hidden ">
                  <div className="items-center h-[30px] w-full flex justify-between mt-1">
                    <div className="flex">
                      <p className="text-[#212121] text-[14px]">Categories</p>
                    </div>
                  </div>
                  <hr className="mt-1" />
                  <div className="pl-3 pt-2 text-[#212121] mb-2 h-[250px] overflow-y-scroll">
                    {/* Loop through category list */}
                    {categoryList.map((categoryItem, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            setCategory(categoryItem.id); // Existing business logic
                            setSelectedCategory(categoryItem.id); // Highlight selected category
                            setAccordionOpen(false); // Close the accordion
                          }}
                        >
                          <p
                            className={`text-[12px] font-[500] mt-2 cursor-pointer ${
                              selectedCategory === categoryItem.id
                                ? "text-[#008081] font-bold" // Highlight selected category CSS
                                : ""
                            }`}
                          >
                            {categoryItem.title}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* mobile  popup*/}

      <div className="flex justify-between mt-5 h-[50px] xl:px-[calc((100%-1250px)/2)] px-3 md:hidden">
        <div className="flex items-center h-[50px]">
          <div className="md:hidden cursor-pointer " onClick={Back}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.25 12.2754L19.25 12.2754"
                stroke="#130F26"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.2988 18.299L4.24883 12.275L10.2988 6.25"
                stroke="#130F26"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <p className="md:text-[32px] pt-2 md:pt-0 text-[16px] h-10 text-[#1F1F1F] ml-1 md:ml-0">
            All Categories
          </p>
        </div>

        <div
          className="text-[#000000] roboto cursor-pointer md:hidden block"
          onClick={togglePopup}
        >
          <button className="flex justify-between w-full px-5">
            <div className=" items-center h-[30px] w-full flex justify-between mt-2">
              <div className="flex items-center">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.211604 1.61C2.5316 4.59 5.9616 9 5.9616 9V14C5.9616 15.1 6.8616 16 7.9616 16C9.0616 16 9.9616 15.1 9.9616 14V9C9.9616 9 13.3916 4.59 15.7116 1.61C16.2216 0.95 15.7516 0 14.9116 0H1.0016C0.171604 0 -0.298396 0.95 0.211604 1.61Z"
                    fill="#008081"
                  />
                </svg>
                <p className="pl-2 text-[#212121] hidden md:block">Filter</p>
              </div>
            </div>
            <img
              src="../images/up.png"
              alt=""
              className={`mr-2 hidden md:block mt-5 transform origin-center rotate-0 transition duration-200 ease-out ${
                accordionOpen && "!rotate-180"
              }`}
            />
          </button>
        </div>
      </div>
      {popup && (
        <div className="absolute bg-[#000000] z-10 bg-opacity-[50%] h-full w-full top-0 left-0 fa">
          <div className="overflow-hidden bg-[#ffffff] h-full rounded-t-[35px] mt-5 mx-5">
            <div className="px-5 pt-5">
              {/* close  */}

              <div className="flex items-center" onClick={togglePopup}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.25 12.2754L19.25 12.2754"
                    stroke="#130F26"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.2988 18.299L4.24883 12.275L10.2988 6.25"
                    stroke="#130F26"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p className="text-[14px]">Back</p>
              </div>

              <div className="">
                <div className="flex items-center justify-center h-[30px]">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.211604 1.61C2.5316 4.59 5.9616 9 5.9616 9V14C5.9616 15.1 6.8616 16 7.9616 16C9.0616 16 9.9616 15.1 9.9616 14V9C9.9616 9 13.3916 4.59 15.7116 1.61C16.2216 0.95 15.7516 0 14.9116 0H1.0016C0.171604 0 -0.298396 0.95 0.211604 1.61Z"
                      fill="#008081"
                    />
                  </svg>
                  <p className="pl-2 text-[#212121]">Filter</p>
                </div>
                <hr className="" />
                <div className=" items-center h-[30px] w-full flex justify-between mt-1">
                  <div className="flex">
                    <p className="text-[#212121] ">Categories</p>
                  </div>
                </div>
                <hr className="mt-1" />
              </div>
              <div className="text-[#212121] mb-2">
                <div
                  onClick={() => {
                    setCategory(0);
                    togglePopup();
                  }}
                >
                  <p className="text-[12px] font-[500] mt-2 cursor-pointer">
                    All
                  </p>
                </div>
                {categoryList.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setCategory(item.id);
                      togglePopup();
                    }}
                  >
                    <p className="text-[12px] font-[500] mt-2 cursor-pointer">
                      {item.title}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* content  */}

      <div className="mt-10 category_page">
        <section className="w-full mb-10 xl:px-[calc((100%-1250px)/2)] lg:px-6">
          <div className="lg:px-0">
            <div className="w-full mb-10 mt-5">
              {serviceList && serviceList.length > 0 ? (
                <div className="grid justify-items-center lg:grid-cols-4 grid-cols-2 md:gap-9 w-full">
                  {serviceList.map((item, index) => (
                    <div
                      onClick={() => OpenPopup(item.serviceId)}
                      key={index}
                      className="cursor-pointer bg-[#ffffff] drop-shadow-lg rounded-t-[12px]  mt-5 md:mt-0 w-[165px] h-[260px] md:w-[320px] md:h-[380px] lg:w-[245px] lg:h-[320px] xl:w-[285px] xl:h-[320px]  relative flex flex-col"
                    >
                      <div className="relative w-full h-full md:h-full overflow-hidden">
                        <img
                          src={item.thumbnails}
                          alt={item.title}
                          className="absolute inset-0 h-full w-full xl:h-[300px] rounded-t-[12px] object-center object-cover"
                          style={{
                            transformOrigin: "center",
                          }}
                        />
                      </div>

                      <div className=" bg-[#008081] p-2">
                        <div className="flex justify-center ">
                          <h1 className="text-[14px] font-normal roboto text-[#ffffff] ">
                            {item.listName}
                          </h1>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center text-gray-500 font-semibold mt-10">
                  Loading ....
                </div>
              )}
            </div>
          </div>
        </section>
      </div>

      {/* load more  */}
      {/* <div className="flex xl:px-[calc((100%-1250px)/2)] py-5 px-3 justify-center">
        <button className="px-3 roboto py-2 border text-[#008081] hover:text-white border-[#008081] rounded-lg hover:bg-[#045A5B]">
          Load More
        </button>
      </div> */}

      {details && (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#868885] bg-opacity-[70%] z-30">
          <div className="overflow-y-scroll text-[#444444] lg:w-[720px] md:w-[450px] bg-white rounded-t-[40px] md:rounded-[0px] pb-10 opacity-100 md:float-right h-full lg:p-6 p-4 m-4 md:m-0">
            <div
              className="flex mt-4 gap-3 cursor-pointer"
              onClick={Closepopup}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.25 12.2754L19.25 12.2754"
                  stroke="#130F26"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.2988 18.299L4.24883 12.275L10.2988 6.25"
                  stroke="#130F26"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p>Back</p>
            </div>
            <div
              className="hidden  float-right cursor-pointer"
              onClick={Closepopup}
            >
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.4 9.99844L0 8.59844L3.6 4.99844L0 1.42344L1.4 0.0234375L5 3.62344L8.575 0.0234375L9.975 1.42344L6.375 4.99844L9.975 8.59844L8.575 9.99844L5 6.39844L1.4 9.99844Z"
                  fill="#787878"
                />
              </svg>
            </div>
            <Popup id={id} Close={Closepopup} />
          </div>
        </div>
      )}
    </>
  );
};

export default Categories;
